var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.plat.sys.dept.title.indexHeadTitle"),
        },
        on: { "head-add": _vm.getNew, "head-romve": _vm.headRomve },
      }),
      _c("grid-head-layout", {
        ref: "searchFrom",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.option,
          tableData: _vm.data,
          "table-loading": _vm.loading,
          gridRowBtn: _vm.gridRowBtn,
          "data-total": _vm.dataTotal,
          page: _vm.page,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "grid-edit": _vm.rowUpdate,
          "grid-romve": _vm.rowDel,
          "tree-load": _vm.treeLoad,
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "grid-addChild": _vm.handleAdd,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }